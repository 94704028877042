@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap);
/* html {
  background: red;
} */
body {
  margin: 0;
  font-family: "Roboto", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner {
  display: flex;
  justify-content: center;
  margin-top: 35vh;
  overflow: hidden;
}
.banner h2 {
  font-size: 38;
  color: #2b2b2b;
}
.applink {
  width: 60%;
  margin: 20vh auto 0px;
  padding: 100px;
  text-align: center;
}
.applink h2 {
  font-weight: 300;
  font-size: 2em;
  color: #2b2b2b;
  margin-bottom: 50px;
}
.applink p {
  margin: 0;
  font-weight: 300;
  color: #020e04;
}
.applink p + p {
  margin-top: 16px;
  /* font-weight: 200; */
}

.mobile_applink {
  width: 100%;
  padding: 100px 0px;
  text-align: center;
}
.mobile_applink h2 {
  font-weight: 400;
  font-size: 1.5rem;
  padding: 0px 20px;
  color: #2b2b2b;
  margin-bottom: 30px;
}
.mobile_applink p {
  font-size: 0.8rem;
  font-weight: 300;
  color: #020e04;
}
.mobile_applink p span {
  font-style: italic;
  opacity: 0.8;
  /* font-weight: 400; */
}
.mobile_applink p + p {
  margin-top: 6px;
  padding: 0px 10px;
  /* font-size: 0.7rem; */
}
a {
  color: #020e04;
}

